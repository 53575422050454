section#programma{
	margin-top:50px;

	.whitespace{
		height:280px;
		background:transparent;
		width:100vw;
		position:absolute;
		z-index:4;
		left:0;
		display:none;
	}

	h1{
		margin: 0;
    	width:100%;

    	@include min-2000{
			 
		}
	}

	ul::-webkit-scrollbar-track{
		//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		border-radius: 10px;
		background-color: #FFFFFF;
		margin-left:5vw;
		margin-right:5vw;

		@include min-2000{
			margin-left:calc((100vw - 1800px) / 2);
			margin-right:calc((100vw - 1800px) / 2);
		}
	}

	ul::-webkit-scrollbar{
		height:15px;
		background-color: #FFFFFF;
	}

	ul::-webkit-scrollbar-thumb{
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(255,255,255,.3);
		background-color: inherit;
	}

	ul {
		list-style:none;
		display:flex;
		flex-wrap: nowrap;
		align-items: center;
		overflow-x: scroll;	
		width:100vw;
		height:310px;
		position:relative;
		margin-left:-50vw;
		left:50%;
		-webkit-overflow-scrolling: touch;
		background-color:rgba(136,136,136,0);
		-webkit-background-clip:text;
		background-clip:text;

		transition: background-color 0.4s ease;

		&:hover{
			background-color:rgba(136,136,136,1);
		}

		@include min-1024{
			height:450px;
		}


		li{
			flex: 0 0 auto;
			height:171px;
			width:240px;
			margin-left:20px;
			border-radius:10px;
			box-shadow: 0 5px 20px rgba(80,80,80,0.5);
			color:white;
			z-index: 10;

			@include min-1024{
				width:360px;
				height:240px;
			}

			&.countryBlues{
				background:url('../assets/schedule/countryBlues.jpg');
			}

			&.reggae{
				background:url('../assets/schedule/reggae.jpg');
			}

			&.soul{
				background:url('../assets/schedule/soul.jpg');
			}

			&.salsa{
				background:url('../assets/schedule/salsa.jpg');
			}

			&.praiseParty{
				background:url('../assets/schedule/praiseParty.jpg');
			}

			&.caribbean{
				background:url('../assets/schedule/caribbean-gospel.jpg');
			}

			&.worship{
				background:url('../assets/schedule/worship.jpg');
			}

			&.currentDay{
				width:300px;
				height:214px;
				box-shadow: 0 5px 30px rgba(80,80,80,0.8);

				span.genre{
					margin-top:40px;
					font-size: 32px;
				}

				@include min-1024{
					width:480px;
					height:320px;
					background-size:cover;
					box-shadow: 0 5px 30px rgba(80,80,80,1);

					span.genre{
						margin-top:90px;
						font-size: 42px;
					}
				}
			}

			&:first-of-type{
				margin-left: 5vw;

				@include min-2000{
					margin-left: calc((100vw - 1800px) / 2);
				}
			}

			&:last-of-type:after{
				content:" ";
				white-space: pre;
				display:block;
				width:calc(100% + 5vw);

				@include min-2000{
					width:calc(100% + (100vw - 1800px) / 2);
				}
			}



			span{
				display:block;
			}

			span.date{
				font-size:16px;
				font-family: "Inter", sans-serif;
				text-align: left;
				padding: 15px 0 0 15px;
				text-shadow: 1px 1px 3px #000;
				font-weight:600;
			}

			span.date.time{
				@extend span.date;
				font-size:13px;
				padding:0 15px;
			}

			span.genre{
				text-align:center;
				margin-top:25px;
				font-size:26px;
				text-shadow: 1px 1px 3px #000;
				font-weight:700;

				@include min-1024{
					margin-top:60px;
					font-size:28px;
				}
			}
		}
	}
}