header{
	font-family:sans-serif;
	background:url('../assets/header-background.jpg');
	padding:50px 20px;
	background-size:     cover; 
    background-repeat:   no-repeat;
    background-position: center center; 
    height: 80vh;
    min-height: 440px;
    max-height: 550px;
    color:white;

    @include min-600{
    	min-height: 550px;
    	max-height: 600px;
    	height:70vh;
    }

    @include min-850{
    	min-height: 600px;
    	max-height: 600px;
    	height:70vh;
    }

    @include min-1024{
    	min-height: 600px;
    	max-height: 700px;
    	height:70vh;
    }

    @include min-1200{
    	min-height:650px;
    	max-height:1000px;
    	height:70vh;
    }

    .rgr-logo{
    	width:100%;
    	max-width:1200px;
    	margin:auto;
    	margin-bottom:20px;

    	@include min-600{
    		width:85%;
    		max-width: 1100px;
    	}
    }

    nav {
    	font-family: "Inter", sans-serif;
		font-size: 20px;
		position: fixed;
		top:0;
		left:0;
		height:100vh;
		width:100vw;
		background:rgba(0,0,0,0.9);
		z-index: 20;
		visibility: visible;
		opacity: 1;
		overflow:auto;
		-webkit-overflow-scrolling: touch;

		-webkit-transition:  opacity 1s ease;
		-o-transition:  opacity 1s ease;
		transition:  opacity 1s ease;

		&.closed{
			visibility: hidden;
			z-index: -2;
			opacity: 0;

			-webkit-transition: opacity 0.4s ease, visibility 0.4s ease 0.4s;
			-o-transition: opacity 0.4s ease, visibility 0.4s ease 0.4s;
			transition: opacity 0.4s ease, visibility 0.4s ease 0.4s;
		}

		&.open{
			visibility: visible;
			opacity: 1;
		}

		ul{
			list-style-type: none;
			text-align:center;

			li {
				margin-bottom:0px;

				&:hover{
					background:rgba(255,255,255,0.2);
				}

				&:first-of-type{
					margin-top:60px;
				}

				&:last-of-type{
					margin-bottom:50vh;
				}

				a{
					color:white;
					text-decoration: none;
					display: block;
					padding:30px;

					&:hover{
						color:white;
					}
				}
			}
		}
    }
}
svg.header {
	position: absolute;
	transform:translateY(-99%);
	width:100%;
}

/*
header nav ul li{
	display: inline;
}

header nav ul li:not(:first-of-type){
	margin-left:15px;
}

header nav ul li a:hover{
	text-decoration: underline;
}*/

header div.pageInfo{
	padding:0px 15px;
	position: absolute;
	margin: auto;
	top:0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	margin-top:130px;

	h1{
		font-size: 48px;
		text-shadow: 1px 1px 10px rgb(80,80,80);
	}

	h2{
		font-family: "Inter", sans-serif;
		font-weight: 600;
		font-size: 18px;
		width:80%;
		margin:auto;
		text-shadow: 1px 1px 8px #000000;

		@include min-600{
			max-width:475px;
		}
	}
}

.Livebutton {
	text-decoration: none;
    display: block;
    margin:auto;
    width: 195px;
    background: #000000;
    padding: 20px 0px;
    text-align: center;
    border-radius: 30px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-top:40px;
    position: relative;
    z-index: 3;
    box-shadow: 0 2px 3px rgba(0,0,0,0.7);

    -webkit-transition: background 0.4s ease;
    -o-transition: background 0.4s ease;
    transition: background 0.4s ease;

    svg{
    	margin-right:7px;
    }

    &:hover{
    	background:#3551db;
    	color:white;
    }
}
/*------------------------------------------------------------------------------------*/

/*------------------------------------------------------------------------------------*/

@media (min-width: 1024px){
	
	.Livebutton{
		padding: 18px 0px;
		width: 225px;
		font-size:16px;
	}
	
	section#topSongs{
		margin-top:50px;
	}

	header div.pageInfo {
		padding: 70px 15px;
	}

	button.menuButton{
		display:none;
	}

	header nav {
	font-size: 20px;
	position: relative;
	background: transparent;

	height: auto;
	width: auto;
	z-index: 20;
	visibility: visible;
	opacity: 1;
	overflow: auto;
	}

	


	header nav ul {
		text-align: left;
	}

	header nav ul li:first-of-type {
		margin-top: 0px;
	}

	header nav ul li{
		display:inline-block;
	}

	header nav ul li:not(:first-of-type){
		margin-left:30px;
	}

	header nav ul li:hover {
		background: transparent;
		text-decoration:underline;
	}

	header nav ul li a {
		display: inline;
		padding: 0px;
		font-size: 16px;
		font-weight:600;
	}

	header nav ul li:last-of-type {
		margin-bottom: 0;
	}

	header div.pageInfo {
		padding: 10px 15px;
	}

	header div.pageInfo h2{
		font-size: 22px;
	}


}