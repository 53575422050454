@mixin min-600 {@media screen and (min-width: 600px){@content;}}
@mixin min-768 {@media screen and (min-width: 768px){@content;}}
@mixin min-850 {@media screen and (min-width: 850px){@content;}}
@mixin min-945 {@media screen and (min-width: 945px){@content;}}
@mixin min-1024 {@media screen and (min-width: 1024px){@content;}}
@mixin min-1200 {@media screen and (min-width: 1200px){@content;}}
@mixin min-1250 {@media screen and (min-width: 1250px){@content;}}
@mixin min-1366 {@media screen and (min-width: 1366px){@content;}}
@mixin min-1482 {@media screen and (min-width: 1482px){@content;}}
@mixin min-1650 {@media screen and (min-width: 1650px){@content;}}
@mixin min-2000 {@media screen and (min-width: 2000px){@content;}}

*{
	margin:0;
	padding:0;
}

html{
	overflow-x:hidden;
}

h1{
	font-family: "Inter", sans-serif;
	font-weight: 800;
	font-size: 28px;
	margin-bottom:20px;

	@include min-850{
		font-size:38px;
	}
}

h2{
	font-family: "Inter", sans-serif;
	font-weight:700;
	margin-bottom:20px;
	margin-top:10px;
	font-size:20px;
}

p{
	font-family: "Rubik", sans-serif;
	line-height:1.5em;
	font-size: 18px;
	margin-bottom:1.5em;
}

.full-width{
	width:100vw;
	position: relative;
	margin-left: -50vw;
	left:50%;
}

.container{
	width:90%;
	max-width:1800px;
	margin:auto;
}


body{
	font-family:"Inter", sans-serif;
	font-weight: normal;
	z-index: 2;
}

button.menuButton{
	position: fixed;
	z-index: 99;
	display: block;
	position: fixed;
	color:#fff;
	background:rgba(0,0,0,0.9);
	top:2.3rem;
	right:1.3rem;
	border: 1px solid #bbb;
	border-radius: 5px;
	opacity: 1;
	visibility: visible;

	color:white;
	text-align:center;
	display: inline-block;
    height: 38px;
    padding: 0 30px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    border: 1px solid #bbb;
    cursor: pointer;
    box-sizing: border-box;


	-webkit-transition:  opacity 1s ease;
	-o-transition:  opacity 1s ease;
	transition:  opacity 1s ease;

	&:hover{
		background:rgba(0,0,0,1);
	}

	&:focus{
		outline:0;
	}

	&.hide{
		opacity: 0;
		visibility: hidden;

		-webkit-transition: opacity 0.4s ease, visibility 0.4s ease 0.4s;
		-o-transition: opacity 0.4s ease, visibility 0.4s ease 0.4s;
		transition: opacity 0.4s ease, visibility 0.4s ease 0.4s;
	}

	&.no-border{
		background:none;
		border:none;
	}

	img{
		height:100%;
	}
}

#switch{
	display:flex;
	flex-wrap:wrap-reverse;

	@include min-1200{
		justify-content:space-between;
	}
}

section#topSongs, section#column{
	flex-basis:100%;

}

@include min-1250{
	section#topSongs{
		flex-basis:35%;
		margin-top:0 !important;

		h1{
			position:sticky;
			top:20px;
		}

		div.ranking{
			position:sticky;
			top:86px;
		}
		
	}
	section#column{
		flex-basis:60%;
		max-width:850px;
	}
}

@include min-1482{
	section#topSongs{
		flex-basis:calc((100% - 850px) - 2%);

		div.ranking{
			max-width:none !important;
		}
	}
}

@include min-1650{
	section#topSongs{
		flex-basis:calc((100% - 850px) - 7%);
	}
}

section#about{
	padding-top:30px;
	padding-bottom:30px;
	background:#eeeeee;
}

@import './header.scss';
@import './footer.scss';
@import './schedule.scss';
@import './encouragement.scss';
@import './top5.scss';
@import './about.scss'