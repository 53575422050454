div#footer{
	background:#eeeeee;
}

svg.footer {
	transform:translateY(13%);
	width:100%;
	z-index: 29;
	background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(238,238,238,1) 96%,rgba(0,0,0,1) 100%);
}

footer{
	padding:30px 0px 50px 0px;
	background:black;
	color:#fff;
	font-family: "AvenirNext-DemiBold", sans-serif;
	text-align: center;
	z-index: -4;

}

footer div.container{
	transform:translateY(-60px);
}

footer h1{
	font-size: 26px;
	font-family: "AvenirNext-DemiBold", sans-serif;
}

footer a{
	color:white;
	text-decoration: none;
}

footer div.contact span{
 display: block;
}

footer div.contact .name{
	font-size: 16px;
}

footer div.contact .email{
	font-family: "AvenirNext-Regular", sans-serif;
	font-size: 16px;
}

footer div.copyright{
	margin-top:20px;
}

footer div.copyright span{
	display:block;
	font-size: 14px;
}

div#footer{
	z-index: 9;
}


@media (min-width: 1051px) and (max-width: 1150px){

	footer div.container {
		transform: translateY(-110px);
	}

	footer{
		padding: 0px 0px 0px 0px;
	}


}/**/

@media (min-width: 1151px) and (max-width: 1450px){

	footer{
		padding:0px 0px 0px 0px;
		height:60px;
	}

	footer div.container {
		transform: translateY(-150px);
	}


}/**/

@media (min-width: 1451px) and (max-width: 1799px){

	footer div.container {
		transform: translateY(-200px);
	}

	footer{
		height:0;
	}
}/**/

@media (min-width: 1800px){

	footer div.container {
		transform: translateY(-300px);
	}

	footer{
		height:0;
		padding: 70px 0px 50px 0px;
	}
}/**/
