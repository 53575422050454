section#topSongs{
	margin-top:40px;
	margin-bottom:50px;

	div.ranking{

		background-image: linear-gradient(#51187B, #371553);
		border-radius:10px;
		color:white;
		width:100%;
		max-width:550px;

		ul{
			list-style-type: none;
			overflow:hidden;

			li{
				border-bottom: 1px solid rgba(255,255,255,0.3);
				display:flex;
				align-items:center;
				justify-content: flex-start;
				height:84px;
				position: relative;

				span.rank{
					font-family: "Inter", sans-serif;
					font-weight:700;
					font-size:30px;
					flex-basis:40px;
					margin-left:15px;

					transition:opacity 0.4s ease;

					&.fadeOut{
						opacity: 0;
					}

				}

				div.songInfo{
					display:flex;
					flex-basis:calc(100% - 40px;);
					flex-direction:column;

					transition: opacity 0.4s ease;

					&.fadeOut{
						opacity: 0;
					}

					span.songName{
						font-family: "Inter", sans-serif;
						font-weight: 700;
						font-size: 16px;
						margin-bottom:2px;
					}

					span.artistName{
						font-family: "Inter", sans-serif;
						font-weight: 400;
						font-size: 13px;
					}
				}

				div.overlay{
					height:85px;
					margin:auto;
					width:100%;
					position:absolute;
					left:calc(100% - 85px);
					right:0;
					overflow:hidden;
					background:rgba(255,255,255,0.2);
					display:flex;
					justify-content:space-between;
					align-items:center;

					transition: left 0.4s ease;
					//display:none;

					@include min-600 {
						width:170px;
						left:calc(100% - 170px);
						right:auto;
						background:rgba(0,0,0,0.1);

						a.options,{
							&:first-of-type{
								display:none;
							}

							&:nth-of-type(2){
								border-left: 1px solid rgba(255,255,255,0.1);
								border-right: 1px solid rgba(255,255,255,0.1);
							}
						}
					}

					&.open{
						left:0;
					}

					a.options{
						height:100%;
						width:85px;
						display:flex;
						justify-content: center;
						align-items: center;

						&:hover{
							background:rgba(255,255,255,0.3);
							cursor:pointer;
						}

						&.noLink{
							pointer-events:none;

							svg{
								filter: grayscale(65%);
								opacity:0.7;
							}
						}

						svg{
							height:28px;
							width:28px;

							&.arrow{
								transition: transform 0.2s 0.1s ease;
								&.rotate{
									transform:rotate(180deg);
								}
							}

							&.youtube{
								color:#FF0000;
								height:38px;
								width:38px;
							}

							&.spotify{
								color:#1DB954;
								height:35px;
								width:35px;
							}
						}
					}
				}
			}
		}
	}
}