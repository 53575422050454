section#about{
	@include min-1250{
		overflow:hidden;
	}

	div.christian-cross{
		display:none;
	}

	div.container{
		@include min-1250{
			display:flex;
			justify-content: space-between;

			div.aboutText{
				flex-basis:850px;
			}

			div.christian-cross{
				display:block;
				flex-basis:calc((100% - 850px));
				background-image:
					linear-gradient(90deg, rgba(238, 238, 238, 1) 2%, rgba(238, 238, 238, 0) 30%),
					linear-gradient(90deg, rgba(238, 238, 238, 0) 90%, rgba(238, 238, 238, 1) 98%),
					linear-gradient(180deg, rgba(238, 238, 238, 1) 2%, rgba(238, 238, 238, 0) 10%),
					linear-gradient(180deg, rgba(238, 238, 238, 0) 90%, rgba(238, 238, 238, 1) 98%),
					url('../assets/music-visual.png');
					/*Background vector created by freepik - https://www.freepik.com/vectors/background*/
				background-repeat: no-repeat;
				background-size:cover;
				opacity: 0.6;
				backdrop-filter: blur(9px);
				//transform:rotate(13deg);

				img{
					width:100%;
				}
			}

		}

		@include min-1366{
			justify-content:flex-start;
		}
		
		p{
			@include min-1250{
				max-width: 850px;
			}
		}

		div.hiddenText{
			max-height:0;
			transform:scaleY(0);
			transform-origin: top;
			opacity: 0;

			transition:max-height 0.5s ease, transform 0.5s ease, opacity 0.5s ease;

			@include min-945{
				transform:scaleY(1);
				max-height:none;
				opacity:1;
			}

			&.show{
				max-height:1000px;
				opacity: 1;
				transform:scaleY(1);
			}
		}

		div.button{

			color:white;
			text-align:center;
			padding: 17px 30px;
			//border:2px solid black;
			border-radius:7px;
			width:120px;
			margin:auto;
			background: #620BDA;
			display:flex;
			flex-direction: row;
			justify-content:space-between;
			align-items: center;

			@include min-945{
				display:none;
			}

			span{
				font-family: "Rubik", sans-serif;
				font-weight:500;

				&:first-of-type{
					font-size:16px;
					flex-basis:80%;
				}

				&:nth-of-type(2){
					margin-top:3px;
					font-size:16px;
					flex-basis:10%;
				}
			}
		}
	}
}