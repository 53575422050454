section#bibleText{
	margin-top:30px;

	div.encouragement{
		span.bibleVerse {
			font-family: "Rubik", sans-serif;
			font-weight:900;
			background:#EEEEEE;
			width:100%;
			border-radius:20px;
			padding:10px 15px;
			font-size: 15px;
			color:#333;
		}

		@include min-945{
			display:flex;
			justify-content: flex-start;
			align-items: center;
			p{
				width:60%;
				max-width:631px;
			}
			span.bibleVerse{
				width: auto;
				height:20px;
				margin-bottom:25px;
				margin-left:30px;
			}
		}
	}
}